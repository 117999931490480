import { productService, stockQuantityService, warehouseService } from 'services/api';
import { loadingActions } from 'features';
import { useAppDispatch } from 'app/store';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import TenantPage from 'components/common/TenantPage';
import WarehouseForm from '../WarehouseForm';
import { useEffect, useRef, useState } from 'react';
import { IOption, IStatusOption, IWarehouse } from 'interfaces';
import { useParams } from 'react-router-dom';
import {
  Card,
  CardContent,
  Button,
  ButtonGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip
} from '@mui/material';
import EntityHistory from 'components/widgets/EntityHistory';
import { EnhancedDataGrid } from 'components/common/enhanced/data-grid';
import { ThresholdsColumns } from 'components/common/enhanced/common-headers/thresholds';
import { StockQuantitiesColumns } from 'components/common/enhanced/common-headers';
import { GridColDef } from '@mui/x-data-grid-premium';
import { productWarehouseService } from 'services/api/ProductWarehouseService';
import { IsSuperAdmin } from 'components/helpers/IsSuperAdmin';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import toast from 'features/toast';
import { IsTechnicianOrAbove } from 'components/helpers/IsTechnicianOrAbove';
import { DocumentTableCardComponent } from 'components/common/documentLM/DocumentTableCardComponent';
import ProductWarehouseForm from '../ProductWarehouseForm';
import { labelThresholdService } from 'services/api/LabelThresholdService';
import TurnedInNotIcon from '@mui/icons-material/TurnedInNot';
import LabelThresholdForm from '../LabelThresholdForm';
import ConfirmationButtonDialog from 'components/helpers/ConfirmationButtonDelete';

const possibleStatusOptions = ['ACTIVE', 'DECOMMISSIONED', 'TO_BE_DECOMMISSIONED', 'TENDER'];

export function EditWarehouse() {
  const translationPrefix = 'pages.warehouse.edit';
  const { warehouseId } = useParams();
  const thresholdsRefresh = useRef(null);

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [warehouse, setWarehouse] = useState<IWarehouse>();
  const [products, setProducts] = useState<IOption[]>([]);
  const [labelOptions, setLabelOptions] = useState<IOption[]>([]);
  const [labelList, setLabelList] = useState<string[]>([]);
  const [statusOptions, setStatusOptions] = useState<IStatusOption[]>([]);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    if (warehouseId) {
      warehouseService.get(+warehouseId).then(setWarehouse);
      productService.getAllBaseInformation().then(setProducts);
    }
    productWarehouseService.options().then((res) => {
      setStatusOptions(
        res.actions.POST.status.choices.map((item) => {
          return {
            ...item,
            display_name: t(`entities.product.status_options.${item.display_name}`)
          };
        })
      );
    });

    // Get label options
    labelThresholdService.getAllPaginated().then((res) => {
      // If there are results set the label list and label_options
      if (res) {
        setLabelList(res.results.map((label) => label.code));
        setLabelOptions(res.results);
      }
    });
  }, []);

  const updateWarehouse = async (values) => {
    dispatch(loadingActions.startLoading());
    warehouseService.update(warehouse.id, values).then((res) => {
      enqueueSnackbar('Magazzino modificato con successo');
    });
    dispatch(loadingActions.stopLoading());
  };

  const handleThresholdsBulkDelete = (id: number) => {
    productWarehouseService.bulkDeleteWarehouse(+warehouseId).then(() => {
      thresholdsRefresh.current();
    });
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const addThreshold = (values) => {
    values['warehouse'] = +warehouseId;
    values['label'] = labelOptions.find((label) => label.id === values.label).code;
    productWarehouseService.add(values).then(() => {
      toast.success(t(`${translationPrefix}.threshold-created`));
      thresholdsRefresh.current();
    });
    handleClose();
  };

  // --------------------------------------------- Create Label Threshold ---------------------------------------------
  const [openLabelDialog, setOpenLabelDialog] = useState(false);

  /**
   * Opens the label dialog.
   */
  const handleOpenLabelDialog = () => {
    setOpenLabelDialog(true);
  };

  /**
   * Closes the label dialog.
   */
  const handleCloseLabelDialog = () => {
    setOpenLabelDialog(false);
  };

  /**
   * Creates a label threshold.
   *
   * @param {Object} values - The values of the label threshold.
   * @return {Promise<void>} A promise that resolves when the label threshold is created.
   */
  const createLabelThreshold = async (values) => {
    // Add the label threshold
    await labelThresholdService.add(values).then(() => {
      // Refresh the label options
      labelThresholdService.getAllPaginated().then((res) => {
        if (res) {
          setLabelList(res.results.map((label) => label.code));
          setLabelOptions(res.results);
        }
      });

      // Show a success toast message
      toast.success(t(`${translationPrefix}.label.created`));

      // Refresh the thresholds
      thresholdsRefresh.current();
    });

    // Close the dialog
    handleCloseLabelDialog();
  };

  const columns: GridColDef[] = StockQuantitiesColumns();
  const thresholdColumns = ThresholdsColumns(labelList, possibleStatusOptions);
  thresholdColumns.splice(3, 1);

  return (
    <TenantPage
      title={t(`${translationPrefix}.title`)}
      subtitle={t(`${translationPrefix}.subtitle`)}
      entityCode={warehouse?.code}>
      {warehouse && (
        <div className="flex flex-wrap">
          <WarehouseForm warehouse={warehouse} onSubmit={updateWarehouse} />

          <div className="w-full p-4">
            <Card variant="outlined">
              <DocumentTableCardComponent
                originalService={'warehouse'}
                objectId={warehouse.id}
                dictParams={{ warehouse: warehouseId }}
              />
            </Card>
          </div>
          {/* Quantities */}
          <div className="w-full p-4">
            <Card variant="outlined">
              <CardContent>
                <b className="text-primary-600">{t('menu.stock')}</b>
                <br />
                <EnhancedDataGrid
                  service={stockQuantityService}
                  columns={columns}
                  outerParams={{
                    warehouse__id: `${warehouse.id}`
                  }}
                />
              </CardContent>
            </Card>
          </div>
          {/* Thresholds */}
          <div className="w-full p-4">
            <Card variant="outlined">
              <CardContent>
                <div className="flex justify-between">
                  <b className="text-primary-600">{t('menu.thresholds')}</b>
                  <br />
                  <ButtonGroup className="px-2">
                    <IsTechnicianOrAbove>
                      <Tooltip title={t(`${translationPrefix}.label.add-label`)}>
                        <Button variant="outlined" size="small" onClick={handleOpenLabelDialog}>
                          <TurnedInNotIcon />
                        </Button>
                      </Tooltip>
                      <Tooltip title={t(`${translationPrefix}.label.add-threshold`)}>
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={() => setOpenDialog(true)}
                          color="success">
                          <AddCircleOutlineIcon />
                        </Button>
                      </Tooltip>
                    </IsTechnicianOrAbove>
                    <IsSuperAdmin>
                      <ConfirmationButtonDialog handleDelete={handleThresholdsBulkDelete} />
                    </IsSuperAdmin>
                  </ButtonGroup>
                </div>
                <EnhancedDataGrid
                  service={productWarehouseService}
                  columns={thresholdColumns}
                  refresh={thresholdsRefresh}
                  outerParams={{
                    warehouse_id: `${warehouse.id}`
                  }}
                />
              </CardContent>
            </Card>
            {/* Threshold Dialog */}
            <Dialog fullWidth maxWidth="lg" open={openDialog} onClose={handleClose}>
              <DialogTitle>{t(`${translationPrefix}.add-threshold`)}</DialogTitle>
              <DialogContent>
                <ProductWarehouseForm
                  products={products}
                  statusOptions={statusOptions}
                  onSubmit={addThreshold}
                  labelOptions={labelOptions}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>{t('actions.cancel')}</Button>
                <Button type="submit" form="addProductThreshold">
                  {t('global.add')}
                </Button>
              </DialogActions>
            </Dialog>
            {/* Label Threshold Dialog */}
            <Dialog fullWidth maxWidth="sm" open={openLabelDialog} onClose={handleCloseLabelDialog}>
              <DialogTitle>{t(`${translationPrefix}.label.add-title`)}</DialogTitle>
              <DialogContent>
                <LabelThresholdForm onSubmit={createLabelThreshold} />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseLabelDialog}>{t('actions.cancel')}</Button>
                <Button type="submit" form="addLabelThreshold">
                  {t('global.add')}
                </Button>
              </DialogActions>
            </Dialog>
          </div>
          <div className="w-full md:w-1/2  p-4">
            <Card variant="outlined">
              <CardContent>
                <EntityHistory service={warehouseService} entityId={warehouse.id} />
              </CardContent>
            </Card>
          </div>
        </div>
      )}
    </TenantPage>
  );
}

export default EditWarehouse;
